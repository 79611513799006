<template>
  <p>活动规则介绍：</p>
  <p>1.任务可领取时间为{{ taskDuration }},超过此时间后任务不可领取；</p>
  <p>2.领取任务需要先开通30天的雷石KTV,支付成功后,则视为任务领取成功;</p>
  <p>3.领取任务后,需要在任务过程中,每日完成一首歌的演唱,则视为签到成功;</p>
  <p>4.任务于用户完成领取后开始倒计时,需要于倒计时内完成对应演唱签到任务;</p>
  <p>5.领取任务于完成任务过程中,需要保持登录状态,否则进度将不被记录;</p>
  <p>6.完成任务后需要与活动页面手动提交,若完成任务后5日日内未提交,则视为放弃任务。</p>
</template>

<script>
export default {
  name: 'ZerobuyTips',
  props: {
    taskDuration: {
      type: String,
      default: ''
    }
  }
}
</script>

<style>

</style>