<template>
  <div class="zb-calendar">
    <div class="zb-calendar-top">
      <div class="zb-calendar-top-left">
        <div class="zb-calendar-top-left-icon" >
          <img src="https://qncweb.ktvsky.com/20230823/vadd/677797259d267350614575d4df4c6aba.png" alt="">
        </div>
        <div class="zb-calendar-top-left-title">
          <p>欢唱打卡日历</p>
          <p>每日完成一首歌的演唱，即视为当日打卡成功</p>
        </div>
      </div>
      <div class="zb-calendar-top-right" v-if="zerobuyStatus === 1">
        <p>再欢唱<span>{{ 30 - sign_count }}</span>天就可以成功啦!</p>
        <p>从报名之日算起，连续演唱打卡30天</p>
      </div>
      <div class="zb-calendar-top-right" v-if="zerobuyStatus === 2">
        <p class="yellow-color">恭喜欢唱成功！快点击【完成任务】领取奖励吧！</p>
      </div>
      <div class="zb-calendar-top-right" v-if="zerobuyStatus === 3">
        <p class="gray-color">下次再接再厉吧~</p>
      </div>
      <div class="zb-calendar-top-right" v-if="zerobuyStatus === 4 || zerobuyStatus === 9">
        <p class="gray-color">退款已在处理中，请耐心等待~</p>
      </div>
      <div class="zb-calendar-top-right" v-if="zerobuyStatus === 5">
        <p class="gray-color">退款已按原支付路径退回</p>
      </div>
      <div class="zb-calendar-top-right" v-if="zerobuyStatus === 6">
        <p class="gray-color">活动已结束</p>
      </div>
      <div class="zb-calendar-top-right" v-if="zerobuyStatus === 7">
        <p class="gray-color">完成任务领取超时</p>
      </div>
      <div class="zb-calendar-top-right" v-if="zerobuyStatus === 8">
        <p class="gray-color">提交失败</p>
      </div>
    </div>
    <div class="zb-calendar-bot" v-if="signinCalendar.length">
      <div
        class="zb-calendar-bot-item"
        :class="{
          'zb-calendar-currday': currTaskDay === index,
          'zb-calendar-forget': item.status === '2',
          'zb-calendar-already': item.status === '1',
        }"
        v-for="(item, index) in signinCalendar" :key="index"
      >
        {{ index + 1 }}
        <img v-if="item.status === '1'" src="https://qncweb.ktvsky.com/20230828/vadd/397879e56e831c5ccc1eeb6ed854f2de.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { useStore } from 'vuex'

export default {
  name: 'ZerobuyCalendar',
  setup(props) {
    const store = useStore()
    const signinCalendar = computed(() => store.state.zerobuy.signinCalendar)
    const remaining_days = computed(() => store.state.zerobuy.remaining_days)
    const sign_count = computed(() => store.state.zerobuy.sign_count)
    const zerobuyStatus = computed(() => store.state.zerobuy.zerobuyStatus)
    const currTaskDay = computed(() => 30 - Number(remaining_days.value) - 1)

    return {
      signinCalendar,
      currTaskDay,
      sign_count,
      zerobuyStatus,
    }
  }
}
</script>

<style lang="stylus" scoped>
.zb-calendar
  width 1760px
  height 673px
  margin-top 35px 
  border-radius 14px
  overflow hidden
  @media screen and (max-width 1200px)
    width 1040px
    height 992px
  &-top
    width 100%
    height 156px
    padding 0 60px
    display flex
    align-items center
    justify-content space-between
    background rgba(30, 31, 33, 1)
    @media screen and (max-width 1200px)
      padding 0 20px
    &-left
      display flex
      align-items center
      &-icon
        width 100px
        height 100px
        background rgba(249, 234, 192, 0.04)
        margin-right 20px
        border-radius 50%
        display flex
        align-items center
        justify-content center
        img
          width 49px
          height 49px
      &-title
        color rgba(255, 255, 255, 0.2)
        font-size 24px
        height 100%
        display flex
        flex-direction column
        justify-content center
        p:nth-child(1)
          color rgba(255, 255, 255, 0.8)
          font-size 36px !important
          margin-bottom 12px
          height 43px
          line-height 43px
          font-weight 600
        p:nth-child(2)
          height 28px
          line-height 28px
        @media screen and (max-width 1200px)
          p:nth-child(2)
            height 25px
            line-height 25px
            font-size 22px
    &-right
      p:nth-child(1)
        color rgba(255, 255, 255, 0.8)
        font-size 28px
        height 56px
        margin-bottom 8px
        display flex
        align-items center
        justify-content center
        span
          font-size 48px
          font-weight 700
          margin 0 10px
          background linear-gradient(90deg, #FBD7AF 0%, #F1BE73 100%)
          background-clip text
          -webkit-background-clip text
          -webkit-text-fill-color transparent
      p:nth-child(2)
        color rgba(255, 255, 255, 0.2)
        font-size 24px
        height 28px
        line-height 28px
      @media screen and (max-width 1200px)
        p:nth-child(1)
          font-size 26px
        p:nth-child(2)
          font-size 22px
      .yellow-color
        margin-top 10px
        background linear-gradient(90deg, #F0D290 0%, #F9EBC3 100%)
        background-clip text
        -webkit-background-clip text
        -webkit-text-fill-color transparent
      .gray-color
        margin-top 10px
        color rgba(255, 255, 255, 0.4) !important
  &-bot
    width 100%
    height 517px
    padding 40px 60px 0 60px
    background rgba(30, 31, 33, 0.7)
    display grid
    grid-template-columns repeat(10, 119px)
    justify-content space-between
    @media screen and (max-width 1200px)
      grid-template-columns repeat(6, 119px)
      height 835px
    &-item
      position relative
      border-radius 14px
      border 1px solid rgba(255, 255, 255, 0.10)
      background rgba(255, 255, 255, 0.07)
      width 119px
      height 119px
      text-align center
      line-height 119px
      color rgba(255, 255, 255, 0.60)
      font-size 36px
      img
        width 40px
        height 40px
        position absolute
        top -20px
        right -20px
  &-currday
    border 2px solid rgba(251, 215, 174, 1)
  &-forget
    border 1px solid rgba(255, 255, 255, 0.04)
    background rgba(255, 255, 255, 0.02)
    color rgba(255, 255, 255, 0.06)
  &-already
    background linear-gradient(90deg, #F0D290 0%, #F9EBC3 100%)
    background-clip text
    -webkit-background-clip text
    -webkit-text-fill-color transparent
</style>